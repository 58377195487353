<template>
	<div :id="'modalUpdateReserve-' + reservation.id" aria-hidden="true" aria-labelledby="modalUpdateReserve" class="modal fade" tabindex="-1">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div id="modalUpdateReserve" class="modal-title">
						<i class="fas fa-exclamation-circle"></i> ¿Deseas modificar esta reserva?</div>
					<button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
				</div>
				<div class="modal-body">

					<div class="info">
						<span>Asegúrate de que sea la reserva que deseas modificar.</span>
					</div>
					<ReservationForm v-if="isOpenUpdateModal" :reservation="reservation"/>
				</div>
				<div class="modal-footer">
					<button class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">Cerrar</button>
					<button :disabled="!isFormReservationReady" class="btn btn-primary" @click="handleUpdateReservation">
						<span v-html="modifyReservationButtonText"></span></button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>



import ReservationForm from "@/components/ReservationForm";
import {mapGetters, mapState} from "vuex";
import * as bootstrap from "bootstrap";
import store from "@/store";
import {hideAllModals} from "@/utils";

export default {
	props:{
		reservation: Object
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
		reservationRepository: 'reservationRepository',
	},
	components:{
		ReservationForm,
	},
	data() {
		return {
			modifyReservationButtonText: 'Modificar reserva',
			isOpenUpdateModal: false,
		};
	},
	computed:{
		...mapState('reservationForm', ['selectedDate', 'selectedPax', 'selectedRoom', 'selectedTime', 'selectedReservationDuration']),
		...mapGetters('reservationForm', ['isFormReservationReady']),
	},
	beforeMount() {

	},
	mounted() {
		const modalUpdate = new bootstrap.Modal(document.getElementById('modalUpdateReserve-' + this.reservation.id));
		modalUpdate._element.addEventListener('hidden.bs.modal', () => {
			this.isOpenUpdateModal = false;
			console.log('El modal se ha cerrado');
		});
		modalUpdate._element.addEventListener('show.bs.modal', () => {
			this.isOpenUpdateModal = true;
			console.log('El modal se ha abierto');
		});
	},
	methods: {
		async handleUpdateReservation() {
			const data = {
				reservationWid: this.reservation.id,
				'reservation-date': this.selectedDate,
				'reservation-time': this.selectedTime,
				'reservation-duration': this.selectedReservationDuration,
				'reservation-pax': this.selectedPax,
				'reservation-room': this.selectedRoom
			}
			this.modifyReservationButtonText = 'Modificando la reserva...&nbsp;&nbsp;<i class="fas fa-spinner fa-spin"></i>'
			console.log(data)
			const resultUpdateReservation = await this.reservationRepository.update({
				client: this.reservation.venueParams.subdomain,
				data
			});
			console.log(resultUpdateReservation);
			if(resultUpdateReservation.result === 0){
				store.commit('user/updateReservation', {reservation: resultUpdateReservation.reservation});
				store.commit("wePass/setToast", {
					toast: {
						toastMessage: `Reserva actualizada correctamente.`,
						showToast: true,
						idToast: "correctlyToast"
					}
				});
				hideAllModals();
			}else{
				store.commit("wePass/setToast", {
					toast: {
						toastMessage: `Ha habido un error actualizando la reserva.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
			}
			this.modifyReservationButtonText = 'Modificar reserva';
		},
	}
};
</script>
