<template src="./passReservationContainer.html"></template>

<script>
import { mapGetters } from 'vuex';

import QRCodeVue from "vue-qrcode";
import PassReservationCard from "@/components/passReservationCard/PassReservationCard";
import FooterWelcom from "@/components/core/FooterWelcom";
import CancelReservationModal from "@/components/mods/cancelReservationModal/CancelReservationModal";

export default {
	data() {
		return {
			contentReservationText: '',
			isValidateUser: false,
		};
	},
	components: {
		PassReservationCard,
		QRCodeVue,
		FooterWelcom,
		CancelReservationModal
	},
	beforeMount() {
		console.log('el usuario antes de montar el container', this.user)
			this.isValidateUser = this.user.type === 2
	},
	mounted() {
		console.log('Todas las reservas desde el contenedor', this.userReservations)
	},
	computed:{
		...mapGetters('user', ['user', 'firstReservation', 'reserveUrl', 'userReservations']),
		...mapGetters('wePass', ['actionParam', 'reservationIdParam']),
		...mapGetters('user', ['firstReservation', 'latestVenueConfig']),
	},
	methods: {
	}
};
</script>
