
const state = {
	actionParam: null,
	reservationIdParam: null,
	toast: {
		showToast: false,
		toastMessage: "",
		idToast: ""
	},
};

const getters = {
	actionParam(state) {
		return state.actionParam;
	},
	reservationIdParam(state) {
		return state.reservationIdParam;
	},
	toast: state => {
		return state.toast;
	},
};

const mutations = {
	loadQueryParamsToState() {
		const queryParams = new URLSearchParams(window.location.search);
		const actionParam = queryParams.get('action');
		const reservationIdParam = queryParams.get('id');
		state.actionParam = actionParam;
		state.reservationIdParam = reservationIdParam
	},
	setToast(state, payload) {
		state.toast = payload.toast;
	},
};

const actions = {

}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
