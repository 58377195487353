class DateFormatterService {
	constructor() {

	}

	formattedDate(dateString) {
		const selectedDate = new Date(dateString);
		const today = new Date();
		const yesterday = new Date(today);
		yesterday.setDate(yesterday.getDate() - 1);
		const tomorrow = new Date(today);
		tomorrow.setDate(tomorrow.getDate() + 1);

		const getMonthName = (monthIndex) => {
			const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
			return months[monthIndex];
		};

		const getDayName = (dayIndex) => {
			const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
			return days[dayIndex];
		};

		if (selectedDate.toDateString() === today.toDateString()) {
			return `Hoy ${getDayName(selectedDate.getDay())} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		} else if (selectedDate.toDateString() === yesterday.toDateString()) {
			return `Ayer ${getDayName(selectedDate.getDay())} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		} else if (selectedDate.toDateString() === tomorrow.toDateString()) {
			return `Mañana ${getDayName(selectedDate.getDay())} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		} else {
			const dayOfWeek = getDayName(selectedDate.getDay());
			return `${dayOfWeek} ${selectedDate.getDate()} de ${getMonthName(selectedDate.getMonth())}`;
		}
	}

	formattedTime(dateString) {
		const dateTime = dateString.split(' ');
		const [hour, minutes] = dateTime[1].split(':');

		const formattedTime = `${hour}:${minutes}`;

		return formattedTime;
	}

}

export const dateFormatterService = new DateFormatterService();
