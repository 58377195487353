<template src="./invitation.html"></template>

<script>
import * as bootstrap from 'bootstrap';
import {mapGetters, mapState} from "vuex";
import HeaderContainer from "@/components/core/HeaderContainer";
import {dateFormatterService} from "@/service/dateFormatterService";
import FooterWelcom from "@/components/core/FooterWelcom";
// import ExitConfirmationModal from "@/components/core/ExitConfirmationModal";


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: 'Invitation',
	components: {
		HeaderContainer: HeaderContainer,
		FooterWelcom,
		// ExitConfirmationModal
	},
	inject: {
		userRepository: 'userRepository',
		allergenRepository: 'allergenRepository',
		reservationRepository: 'reservationRepository',
		manageToken: 'manageToken',
		dateFormatterService: 'dateFormatterService'
	},

	data() {
		return {
			selectedInvitedUser: null,
			signupEmail: '',
			signupGender: null,
			signupName: null,
			signupTerms: false,
			reservationWid: '',
			hostCode: '',
			currentStep: 1,
			showErrorMessageForm: false,
			errorMessage: '',
			showErrorMessageEmail: false,
			responseMessage: 'Por favor, introduce una dirección de correo electrónico válida.',
			date: '',
			time: '',
			textTitle: 'Invitación'
		};
	},
	async beforeMount() {
		console.log('Antes de cargar componente invitacion, este es el usuario: ', this.user);

		this.date = dateFormatterService.formattedDate(this.reservationInfo.startDateTime)
		this.time = dateFormatterService.formattedTime(this.reservationInfo.startDateTime)


	},
	mounted() {
		console.log('El usuario creador de la reserva y es valida la invitacion: ', this.creatorUser);

		this.userDetectionOpeningModal()

		this.removeQueryParams()
	},
	computed: {
		...mapState('user', ['user', 'userReservations']),
		...mapGetters('invitation', ['isValidReservation', 'reservationInfo', 'venueParams', 'invitedUsers', 'creatorUser', 'hostUserName', 'isInvitationMenu', 'isWalking']),
		isPaxLimit() {
			return this.paxLimitConfirmation(this.reservationInfo.pax, this.reservationInfo.reservationUsers);
		},
		acceptButtonText() {
			if (this.isValidReservation) {
				if (this.selectedInvitedUser === 1 || !this.selectedInvitedUser) {
					return 'Selecciona un nombre';
				} else {
					return 'Confirmar nombre';
				}
			} else {
				return 'Selecciona un nombre';
			}
		}
	},
	watch: {
		selectedInvitedUser: function () {
			this.signupName = '';
		},
		signupName: function (newTexto) {
			if (newTexto !== '' && this.selectedInvitedUser !== 1) {
				this.selectedInvitedUser = null;
			}
		}
	},
	methods: {

		removeQueryParams() {
			let url = window.location.href;
			const urlParts = url.split('?');

			if (urlParts.length >= 2) {
				url = urlParts[0];
				window.history.replaceState({}, document.title, url);
			}
		},
		paxLimitConfirmation(pax, reservationUsers) {
			const maxPax = pax;
			const numberUserConfirm = reservationUsers.length;
			console.log('Número máximo de ocupantes permitidos:', maxPax);
			console.log('Número de usuarios confirmados:', numberUserConfirm);

			if (numberUserConfirm >= maxPax) {
				console.log('Se ha alcanzado el límite de ocupantes.' + true);
				return true;
			}
			console.log('Todavía hay espacio disponible.' + false);
			return false;
		},

		userDetectionOpeningModal() {
			if (this.user && this.reservationInfo) {
				const modalId = document.getElementById('modalUserFoundByToken');

				if (modalId) {
					const modalElement = new bootstrap.Modal(modalId, {
						backdrop: 'static',
						keyboard: false
					});

					modalElement.show();
				} else {
					console.error('Modal element with ID "modalUserFoundByToken" not found.');
				}
			} else {
				console.error('User or reservationInfo is not available yet.');
			}
		},

		async submitAcceptInvitationReservation(userFromModal = false) {
			event.preventDefault();
			if (userFromModal) {
				const existingUser = this.reservationInfo.reservationUsers.find(user => user.userId === this.user.id && user.typeHost > 0);
				if (existingUser) {
					this.selectedInvitedUser = existingUser.uid;
					window.location.href = `${window.location.origin}/?uid=${this.selectedInvitedUser}`
					return;
				} else {
					const result = await this.reservationRepository.addInvitedUser(this.reservationInfo.id, this.user.id);
					console.log(result)
					if (result.result === 0) {
						window.location.href = `${window.location.origin}/?uid=${this.user.hostCode}`
					}
					return
				}
			}


			if (this.selectedInvitedUser === 1 || !this.selectedInvitedUser) {
				if (!this.signupName) {
					alert('El nombre debe tener un valor');
				}

				const response = await this.reservationRepository.addUnknownInvitedUser(this.reservationInfo.id, this.hostCode, this.signupName);

				if (response.result === 0) {
					window.location.href = `${window.location.origin}/?uid=${response.uid}`;
				}
				console.log(response);
			} else {
				const user = {...this.reservationInfo.reservationUsers.find(user => user.uid === this.selectedInvitedUser)};

				if (user.typeHost === 2) {
					await this.userRepository.sendPass(user.uid);
					const modal = document.getElementById('modal-check-inbox-reservation-invitation');
					const bootstrapModal = new bootstrap.Modal(modal);
					bootstrapModal.show();
					return;
				}

				window.location.href = `${window.location.origin}/?uid=${this.selectedInvitedUser}`;
			}
		},
		async submitAcceptInvitationMenu(userFromModal = false) {
			event.preventDefault();
			console.log('viene del modal??', userFromModal)
			if (userFromModal) {
				const existingUser = this.reservationInfo.reservationUsers.find(user => user.userId === this.user.id);
				if (existingUser) {
					this.selectedInvitedUser = existingUser.uid;
					window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.selectedInvitedUser}`;
					return;
				} else {
					const result = await this.reservationRepository.addInvitedUser(this.reservationInfo.id, this.user.id);
					if (result.result === 0) {
						window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.user.hostCode}`
					}
					return
				}
			}

			if (this.selectedInvitedUser === 1 || !this.selectedInvitedUser) {
				if (!this.signupName) {
					alert('El nombre debe tener un valor');
					return;
				}


				const data = {
					invitedUserName: this.signupName,
					hostCode: this.hostCode,
					reservationWid: this.reservationInfo.id
				};
				const response = await this.userRepository.createGuestUserMenu(data);
				if (response.result === 0) {
					const invitedUser = response.invitedUser;
					invitedUser.creationDate = new Date();
					this.manageToken.setToken(response.token);
					window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${invitedUser.hostCode}`;
				}
			} else {
				window.location.href = `https://${this.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${this.reservationInfo.id}&uid=${this.selectedInvitedUser}`;
			}
		}
	},
}
</script>
