<div class="content-center-justify" :data-bs-theme="venueParams.resource_id">
	<header-container :image-restaurant="venueParams.resource_id" :subTitle="venueParams.business_name" :text-title="textTitle" :reservation-info="reservationInfo"/>

	<!--	INVITACION RESERVA-->
	<div class="container-section" v-if="!isInvitationMenu && reservationInfo">

		<section class="border-responsive">
			<div class="section-header">
				<div class="section-subtitle">
					<span v-if="isValidReservation">{{ hostUserName }} tiene una reserva y cuenta con tu asistencia. </span>
					<span v-else>Esta reserva fue creada por <span class="fw-semibold text-center text-md-start">{{ hostUserName }}</span>.</span>
				</div>
			</div>
		</section>

		<div class="container p-0">
			<div class="card">
				<div class="card-header">
					<h3>{{ date }}</h3>
				</div>
				<div class="row g-0 justify-content-center">
					<div class="align-items-center h-100">
						<div class="info-inblock info-inblock-primary">
							{{ time }} h
							<div class="vr-point"></div>
							{{ reservationInfo.pax }} Pers
							<div class="vr-point"></div>
							{{ reservationInfo.roomName }}
						</div>
						<div class="hidden display-md">
							<ul class="list-group list-group-flush">
								<li class="list-group-item"></li>
							</ul>
						</div>
					</div>
					<div class="col-md-8">
						<div class="card-body h-100 align-content-center">
							<div v-if="invitedUsers.length" class="text-start">
								<p class="text-center" v-if="isValidReservation">Asistentes confirmados</p>
								<p v-else>Asistentes a la reserva</p>

								<div v-for="invitedUser in invitedUsers" :key="invitedUser.uid" class="form-check">
									<input v-if="isValidReservation" class="form-check-input" v-model="selectedInvitedUser" type="radio" name="inlineRadioOptions" :id="invitedUser.uid" :value="invitedUser.uid" :disabled="invitedUser.typeHost === 2">
									<label class="form-check-label" :for="invitedUser.uid">{{ invitedUser.name }}</label>
								</div>
								<div v-if="!isPaxLimit && isValidReservation" class="form-check">
									<input class="form-check-input" v-model="selectedInvitedUser" type="radio" name="inlineRadioOptions" :id="1" :value="1">
									<label class="form-check-label" :for="1">Soy otra persona</label>
								</div>
							</div>

							<div v-if="selectedInvitedUser === 1 || (!selectedInvitedUser && !invitedUsers.length) || isValidReservation && !isPaxLimit">
								<p v-if="selectedInvitedUser === 1"> ¿No eres ninguno de ellos?
									<b>¡ingresa tu nombre!.</b></p>
								<p v-if="(!selectedInvitedUser && !invitedUsers.length)">Ingresa tu nombre y acepta la asistencia.</p>
								<div v-if="selectedInvitedUser === 1 || !invitedUsers.length" class="input-group">
									<span class="input-group-text"><i class="fas fa-user"></i></span>
									<input type="text" class="form-control" placeholder="Ingresa tu Nombre (sin apellidos):" v-model="signupName">
								</div>
							</div>
							<div class="card-content-btn">
								<button v-if="isValidReservation" class="btn btn-primary w-100 mt-3" @click="submitAcceptInvitationReservation(false)"
										:disabled="(!signupName && (selectedInvitedUser === 1 || !selectedInvitedUser))">
									{{ acceptButtonText }}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>

	<!--	INVITACION MENU-->
	<div v-if="isInvitationMenu">
		<div class="container p-0 mt-3">
			<div class="card">
				<div class="card-header" v-if="creatorUser">
					<h3 v-if="!isWalking">{{ creatorUser.name }} te ha compartido la carta</h3>
					<h3 v-else>Accede a nuestra carta</h3>
				</div>
				<div class="row g-0 justify-content-center">
					<div class="align-items-center h-100 ">
						<div class="info-inblock info-inblock-primary">
							{{ time }} h
							<div class="vr-point"></div>
							{{ reservationInfo.pax }} Personas
							<div class="vr-point"></div>
							{{ reservationInfo.roomName }}
						</div>
					</div>

					<div class="col-md-8">
						<div class="card-body h-100 align-content-center">
							<div v-if="invitedUsers.length">
								<p class="text-center" v-if="isValidReservation">
									Selecciona quién eres de la lista de asistentes o indica que eres otra persona si no apareces listado.
								</p>

								<div v-if="!isWalking" class="col-md-6 col-lg-auto text-start">
									<div v-if="creatorUser" :key="creatorUser.uid" class="form-check">
										<input disabled v-if="isValidReservation" v-model="selectedInvitedUser" :id="creatorUser.uid" :value="creatorUser.uid" class="form-check-input" type="radio">
										<label class="form-check-label" :for="creatorUser.uid">
											{{ creatorUser.name }}
										</label>
									</div>
								</div>

								<div v-for="invitedUser in invitedUsers" :key="invitedUser.uid" class="col-md-6 col-lg-auto text-start">
									<div class="form-check">
										<input v-if="isValidReservation" v-model="selectedInvitedUser" :id="invitedUser.uid" :value="invitedUser.uid" :disabled="invitedUser.typeHost === 1" class="form-check-input" type="radio">
										<label class="form-check-label" :for="invitedUser.uid">
											{{ invitedUser.name }}
										</label>
									</div>
								</div>

								<div class="col-md-6 col-lg-auto text-start">
									<div v-if="isValidReservation && !isPaxLimit" class="form-check">
										<input v-if="isValidReservation" v-model="selectedInvitedUser" :id="1" :value="1" class="form-check-input" type="radio">
										<label class="form-check-label" :for="1">
											Soy otra persona
										</label>
									</div>
								</div>

							</div>

							<div v-if="(selectedInvitedUser === 1 || (!invitedUsers.length) )&& isValidReservation/* && !isPaxLimit*/">
								<p class="text-center">¡Déjanos conocerte! Introduce tu nombre para ofrecerte una atención personalizada.</p>

								<div v-if="isValidReservation && !isPaxLimit" class="input-group mb-3">
									<span class="input-group-text"><i class="fas fa-user"></i></span>
									<input type="text" class="form-control" placeholder="Ingresa tu Nombre (sin apellidos):" v-model="signupName">
								</div>
							</div>

							<div class="row">
								<div class="col-12">
									<p class="text-center">
										¡Una vez que ingreses tu nombre, podrás disfrutar de hacer pedidos sin esperas directamente desde tu teléfono!
									</p>
								</div>
							</div>

							<div class="row-center" v-if="isValidReservation">
								<button type="button" class="btn btn-primary w-100" @click="submitAcceptInvitationMenu(false)" :disabled="(!signupName && (selectedInvitedUser === 1 || !selectedInvitedUser))">
									<span v-if="/*isPaxLimit &&*/ selectedInvitedUser !== 1 && selectedInvitedUser">Confirmar nombre</span>
									<span v-else>Selecciona un nombre</span>
								</button>
							</div>

							<div id="reservationResponseCollapse" v-if="showErrorMessageEmail" class="col-12">
								<div class="alert alert-danger" role="alert">
									¿Cómo podemos llamarte?
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="container">
			<div class="section-header">
				Crea tu cuenta y obtén tu QR único para disfrutar de múltiples beneficios:
			</div>
			<div class="section-body">
				<ul>
					<li>Haz reservas fácilmente desde tu móvil.</li>
					<li>Accede a la carta digital y realiza pedidos al instante.</li>
					<li>Descubre y disfruta de tus restaurantes favoritos.</li>
					<li>Disfruta de momentos únicos con tus seres queridos.</li>
				</ul>
			</div>
		</section>

	</div>

	<!-- Modal -->
	<div v-if="user" class="modal fade" id="modalUserFoundByToken" tabindex="-1" aria-labelledby="modalUserFoundByTokenLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="modalUserFoundByTokenLabel">¡Hola {{user.name}}!</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
				</div>
				<div class="modal-body text-center">
					<p>
						<span v-if="isValidReservation && !isInvitationMenu"><b>{{ hostUserName }}</b> tiene una reserva y espera contar con tu asistencia.</span>
						<span v-if="isInvitationMenu && isValidReservation"><b>{{ hostUserName }}</b> te ha compartido la carta. Acepta para pedir desde tu móvil.</span>
					</p>

					<div v-if="isValidReservation && !isInvitationMenu" class="container p-0">
						<div class="card">
							<div class="card-header">
								<h3>{{ date }}</h3>
							</div>
							<div class="row g-0 justify-content-center">
								<div class="align-items-center h-100">
									<div class="info-inblock info-inblock-primary">
										{{ time }} h
										<div class="vr-point"></div>
										{{ reservationInfo.pax }} personas
										<div class="vr-point"></div>
										{{ reservationInfo.roomName }}
									</div>
								</div>
								<div class="col-md-8">
									<div class="card-body h-100 align-content-center">
										<div v-if="invitedUsers.length" class="text-start">
											<div><b>Asistentes confirmados</b></div>
											<span v-for="(invitedUser, index) in invitedUsers" :key="invitedUser.uid">
                                            <span>{{ invitedUser.name }}</span>
                                            <span v-if="index !== invitedUsers.length - 1">, </span>
                                            <span v-if="index === invitedUsers.length - 1">.</span>
                                        </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal">Soy otra persona</button>

					<button v-if="!isInvitationMenu && reservationInfo" type="button" class="btn btn-primary" @click="submitAcceptInvitationReservation(true)">Aceptar</button>
					<button v-if="isInvitationMenu" type="button" class="btn btn-primary" @click="submitAcceptInvitationMenu(true)">Acceder</button>
				</div>
			</div>
		</div>
	</div>

<!--	<ExitConfirmationModal/>-->

	<footer-welcom></footer-welcom>
</div>
