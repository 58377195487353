<div :id="'modalCancelAssistance-' + reservation.id" aria-hidden="true" aria-labelledby="modalLabelCancelAssistance" class="modal fade" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<div id="modalLabelCancelAssistance" class="modal-title">
					<i class="fas fa-exclamation-circle"></i> ¿Deseas cancelar tu asistencia a esta reserva?
				</div>
			</div>
			<div class="modal-body">
				<p>Para cancelar tu asistencia, haz clic en el botón <b>"CANCELAR"</b> de esta ventana.</p>

				<div class="reserve-card-content-modal">
					<div class="row">
						<div class="col-12">
							<i class="fas fa-calendar"></i> {{ date }}
						</div>
					</div>

					<div class="row">
						<div class="col-4">
							<div>
								<i class="fas fa-clock"></i> {{ time }}h
							</div>
						</div>
						<div class="col-3">
							<div>
								<i class="fas fa-users"></i> {{ reservation.pax }}
							</div>
						</div>
						<div class="col-5">
							<div v-if="reservation.roomName">
								<svg class="table-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 20 140 80" width="200" fill="">
									<rect x="10" y="30" width="120" height="20" rx="5" ry="5" fill="inherit"/>
									<rect x="20" y="40" width="20" height="50" rx="5" ry="5" fill="inherit"/>
									<rect x="100" y="40" width="20" height="50" rx="5" ry="5" fill="inherit"/>
								</svg>
								{{ reservation.roomName }}
							</div>
						</div>
					</div>
				</div>
				<div class=" info text-center">
					Asegúrate de que sea la asistencia que deseas cancelar.
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">Cerrar</button>
				<button class="btn btn-primary" @click="handleRemoveReservationInvitee">
					<span v-html="cancelAssistanceButtonText"></span></button>
			</div>
		</div>
	</div>
</div>
