<template src="./cancelAssistanceModal.html"></template>
<script>
import {dateFormatterService} from "@/service/dateFormatterService";
import {mapGetters} from "vuex";
// import * as bootstrap from "bootstrap";
import store from "@/store";

export default {
	props:{
		reservation: Object
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	data() {
		return {
			date:'',
			time:'',
			cancelAssistanceButtonText: 'Cancelar asistencia',
		};
	},
	computed:{
		...mapGetters('user', ['user']),
		isCreator(){
			return this.reservation.reservationUsers.filter(user => user.userId === this.user.id && user.type === 1).length > 0;
		}
	},
	beforeMount() {
		console.log('Montando el modal de cancelar reservation');
		this.date = dateFormatterService.formattedDate(this.reservation.startDateTime)
		this.time = dateFormatterService.formattedTime(this.reservation.startDateTime)

	},
	mounted() {
	},
	methods: {
		async handleRemoveReservationInvitee() {
			console.log("Cancelando invitación a la reserva");
			this.cancelAssistanceButtonText = "Cancelando asistencia..."
			const result = await store.dispatch('user/deleteAssistanceReservation', {reservation: this.reservation})
			if (result.result < 0) {
				console.log("No se ha cancelado la invitación");
			} else if (result.result === 0) {
				console.log("Invitación cancelada con éxito");
			}
		},
	}
};
</script>
