import {userRepository} from "@/service/repository/userRepository";
import {cookieManager} from "@/service/cookieManager";
import {reservationRepository} from "@/service/repository/reservationRepository";
import {hideAllModals} from "@/utils";

const state = {
    user: null,
    userReservations: []
};

const getters = {
	firstReservation(state) {
		const allReservations = [...state.userReservations];
		allReservations.sort((a, b) => {
			const dateA = new Date(a.startDateTime);
			const dateB = new Date(b.startDateTime);
			return dateA - dateB;
		});

		return allReservations.length > 0 ? allReservations[0] : false;
	},
	userReservations(state) {
		return state.userReservations;
	},
	user(){
		return state.user;
	},
	latestVenueConfig(){
		return state.user.latestVenueConfig;
	},
	reserveUrl(){
		if(state.user.latestVenueConfig && state.user.latestVenueConfig.subdomain){
			return `https://${state.user.latestVenueConfig.subdomain}.wewelcom.io/reservar`
		}else{
			return ``
		}

	}
};

const mutations = {
    setUser(state, payload) {
		//console.log("Estableciendo usuario", payload.user)
        state.user = payload.user;
		state.user = {...state.user, latestVenueConfig: payload.latestVenueConfig};
    },
    setUserReservations(state, payload) {
        state.userReservations = payload.userReservations;
    },
    eraseReservation(state, payload) {
		console.log('Las reservas antes de cancelar:', state.userReservations.length)
        state.userReservations = state.userReservations.filter(
            reservation => reservation.id !== payload.reservationId
        );
		console.log('Las reservas antes de cancelar:', state.userReservations.length, "después de cancelar:", payload.reservationId )
    },
	updateReservation(state, payload) {
		console.log('el payload', payload)
		state.userReservations = state.userReservations.map(
			reservation => {
				console.log(reservation.id, payload.reservation.id, reservation.id === payload.reservation.id)
				return reservation.id === payload.reservation.id ? payload.reservation : reservation
			}
		);
		console.log('Las reservas antes de modificar:', state.userReservations )
	}
};

const actions = {
	async find({ commit }, userId) {
		try {
			const findUserResult = await userRepository.find(userId);
			if (findUserResult.result === 0){
				commit('setUser', findUserResult);
				cookieManager.setToken(findUserResult.token)
				return findUserResult
			} else {
				cookieManager.clearToken();
				console.log("No se ha encontrado ningun usuario con ese UID");
				return findUserResult
			}
		} catch (error) {
			console.error("Failed to fetch user", error);
		}
	},

	async loginWithToken({ commit }, token) {
		try {
			const findUserResult = await userRepository.loginWithToken(token);
			if (findUserResult.result === 0){
				console.log(findUserResult, 'el usuario que devuelve logar con token')
				commit('setUser', findUserResult);
				cookieManager.setToken(findUserResult.token)
				return findUserResult
			} else {
				cookieManager.clearToken();
				console.log("No se ha encontrado ningun usuario con ese token");
				return findUserResult
			}
		} catch (error) {
			console.error("Failed to fetch user", error);
		}
	},

	async deleteReservation({ commit }, {reservation}){
		const result = await reservationRepository.cancelReservation(reservation.id, reservation.venueParams.subdomain);
		if (result.result < 0) {
			console.log("No se ha cancelado la reserva");
			return result
		} else if (result.result === 0) {
			console.log("Reserva cancelada con éxito");
			hideAllModals();
			commit('eraseReservation', {reservationId:reservation.id})
			return result
		}

	},

	async deleteAssistanceReservation({ commit }, {reservation}){
		const result = await reservationRepository.cancelAssistanceReservation(reservation.id, state.user.id);
		if (result.result < 0) {
			console.log("No se ha cancelado la invitación");
			return result
		} else if (result.result === 0) {
			console.log("Invitación cancelada con éxito");
			hideAllModals();
			commit('eraseReservation', {reservationId:reservation.id})
			return result
		}

	},
	async loadUserReservations({ commit }){
		const result = await userRepository.getReservationsUser(state.user.hostCode)
		if (result.result < 0) {
			console.log("Algo ha ido mal cargando las reservas del usuario");
			return result
		} else if (result.result === 0) {
			const userReservations = result.userReservations.filter(reservation => reservation.status === 1).sort((a, b) => {
				const dateA = new Date(a.startDateTime);
				const dateB = new Date(b.startDateTime);
				return dateA - dateB;
			});
			console.log("las reservas del usuario desde el modulo", userReservations);
			commit('setUserReservations', {userReservations});
		}

	}
}

export default {
    namespaced: true,
    state,
    mutations,
	actions,
	getters
};
