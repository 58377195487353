import store from "@/store";
export class WebSocketClient {

	static ACTION_ENABLE_MENU_BUTTON = 0;
	static ACTION_UPDATE_RESERVATION_ORDERS = 1;
	static ACTION_SEND_NOTIFICATION_USER_VALIDATE = 2;
	static ACTION_SEND_NOTIFICATION_RESERVATION_VALIDATE = 3;
	static ACTION_UPDATE_RESERVATION_IN_COPILOT = 4;

	constructor(componentReference = null, url, port) {
		if (componentReference) {
			this.componentReference = componentReference;
		}
		this.url = url;
		this.port = port;
		this.socket = null;
		this.reconnectInterval = null;
		this.intervalNumber = 0;
	}

	connect(clientId) {
		// Concatenar la URL del servidor WebSocket con la información adicional
		//const socketUrl = `wss://wss.wewelcom.io:2053/?uid=${clientId}`;
		const socketUrl = `${this.url}:${this.port}/?uid=${clientId}`;

		// Crear una nueva instancia de WebSocket
		try {
			this.socket = new WebSocket(socketUrl);
		} catch (e) {
			console.error('Error del WebSocket:', e.message);
		}

		// Manejar eventos de la conexión WebSocket
		this.socket.addEventListener("open", (event) => {
			console.log("Conexión establecida:", event);
			clearInterval(this.reconnectInterval);
			if (this.intervalNumber > 0 && this.componentReference) {
				this.intervalNumber = 0;
				this.componentReference.updateOrders ? this.componentReference.updateOrders() : null;
			}

			if (this.componentReference) {
				this.componentReference.colorConnection = 'transparent'
			}
		});

		this.socket.addEventListener("message", async (event) => {
			console.log('recibiendo mensaje en WS');
			const message = JSON.parse(event.data);

			const action = message.action;

			switch (action) {
				case WebSocketClient.ACTION_ENABLE_MENU_BUTTON: {
					const reservations = message.data;
					console.log('Desde el WS', reservations)
					await store.dispatch('user/loadUserReservations')
					break;
				}

			}

		});

		this.socket.addEventListener("close", (event) => {
			console.log("Conexión cerrada:", event);
			if (this.componentReference && this.intervalNumber === 0) {
				this.componentReference.colorConnection = 'Red'
			}

			if (this.reconnectInterval) {
				clearInterval(this.reconnectInterval);
			}
			this.reconnectInterval = setInterval(() => {
				this.intervalNumber % 2 === 0 ? this.componentReference.colorConnection = 'Yellow' : this.componentReference.colorConnection = 'Blue'
				console.log("Intentando reconectar...");
				this.connect(clientId);
				this.intervalNumber++
			}, 2000);
		});


		this.socket.addEventListener("error", (event) => {
			console.error("Error en la conexión:", event);
		});
	}

	// Método para enviar mensajes al servidor
	sendMessage(message) {
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message));
		} else {
			console.error("La conexión WebSocket no está abierta.");
		}
	}

	// Método para cerrar la conexión
	close() {
		if (this.socket) {
			this.socket.close();
		}
	}

}
