<template>
	<header class="header-container" :class="{'header-we-order': isInvitationRoute}">
		<div class="header-content" :style="computedStyles">
			<h1>{{ textTitle }} <b>{{ textName }}</b></h1>
			<h2>{{ subTitle }}</h2>
		</div>
	</header>
</template>

<script>
export default {
	props: {
		subTitle: String,
		imageRestaurant: String,
		textTitle: String,
		urlRestaurant: String,
		textName: String,
	},
	computed: {
		computedStyles() {
			return {
				...this.headerStyle,
				'min-height': '6rem'
			};
		},
		headerStyle() {
			const baseUrl = this.isInvitationRoute ? '' : null;
			return {
				backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.667)), url('${baseUrl}/world/img/${this.imageRestaurant}/header/header.jpg')`
			};
		},
		isInvitationRoute() {
			return this.$route.path.includes('/invitacion');
		}
	},
};
</script>
