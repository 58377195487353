import {sendRequest, sendRequestJson} from '@/utils'
import configModule from "@/store/configModule";

class UserRepository {

	constructor() {

		let host = window.location.hostname;
		if (host === "localhost"){
			this.urlBase = 'https://dev.wepass.ai';
		} else {
			this.urlBase = window.location.origin;
			//this.urlBase = configModule.state.wePassUrl;
		}
	}

	async find(uid) {
		//const url = configModule.state.wePassUrl + "/world/api/user/find"
		const url = configModule.state.wePassUrl + "/world/api/user/find"
		//console.log(`Buscando la info de usuario ${uid} de ${configModule.state.wePassUrl}`);
		console.log(`Buscando la info de usuario ${uid} de ${url}`);
		try {
			return await sendRequestJson({data:{uid}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo la información del usuario.");
		}
	}

	async registerUserFromInvitation(data) {
		const url = this.urlBase + "/world/user/register"
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando creando el usuario por invitación");
		}
	}

	async createGuestUserMenu(data) {
		const url = this.urlBase + "/world/api/user/createguestusermenu";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando creando el usuario invitado para el menu");
		}
	}

	async sendPass(uid) {
		const data = {uid}
		const url = this.urlBase + "/world/api/user/sendpass";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando creando el usuario invitado para el menu");
		}
	}

	async getReservationsUser(uid) {
		const url = configModule.state.wePassUrl + "/world/reservation/userfuture";
		return await sendRequestJson({data:{uid}, url, method:"POST"});
	}

	async registerLog(data, client) {
		const url = `https://${client}.wewelcom.io` + "/world/doorlog/register";
		try {
			return await sendRequestJson({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal terminando creando el usuario invitado para el menu");
		}
	}

	async checkLog(welkhomeKey, userId) {
		const currentDate = new Date();
		const limitDate = new Date(currentDate);
		limitDate.setDate(currentDate.getDate() - 1);
		const data = {
			welkhomeKey: welkhomeKey,
			dateFrom: limitDate,
			dateTo: currentDate,
			userId: userId
		};
		const url = `${configModule.state.wePassUrl}/world/doorlog/history`;
		try {
			return await sendRequest({data, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal consultando los logs");
		}
	}

	async updateAllergens(uid, allergensId) {
		const url = configModule.state.wePassUrl + "/world/api/user/updateallergens";
		try {
			return await sendRequestJson({data:{uid, allergensId}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal actualizando las alergias del usuario");
		}
	}

	async sendComment(uid, comment) {
		const url = configModule.state.wePassUrl + "/world/api/webcontact/sendcomment";
		try {
			return await sendRequestJson({data:{uid, comment}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algo ha ido mal enviando el comentario");
		}
	}

	async login(userEmail, password) {
		const url = configModule.state.wePassUrl + "/world/api/user/login";
		try {
			return await sendRequestJson({data:{userEmail, password}, url, method:"POST"});
		} catch (e) {
			console.log("Ups, algoo ha ido mal logeando al usuario", e);
		}
	}

	async loginWithToken(token) {
		const url = configModule.state.wePassUrl + "/world/api/user/logintoken";
		try {
			return await sendRequestJson({ url, method:"GET", token});
		} catch (e) {
			console.log("Ups, algoo ha ido mal logando al usuario con token", e);
		}
	}

}

export const userRepository = new UserRepository();
