<template src="./cancelReservationModal.html"></template>
<script>
import store from "@/store";
import {dateFormatterService} from "@/service/dateFormatterService";
import {mapGetters} from "vuex";
import * as bootstrap from "bootstrap";

export default {
	props:{
		reservation: Object
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	data() {
		return {
			date:'',
			time:'',
			cancelReservationButtonText: 'Cancelar reserva',
		};
	},
	computed:{
		...mapGetters('user', ['user']),
		isCreator(){
			return this.reservation.reservationUsers.filter(user => user.userId === this.user.id && user.type === 1).length > 0;
		}
	},
	beforeMount() {
		console.log('Montando el modal de cancelar reservation');
		this.date = dateFormatterService.formattedDate(this.reservation.startDateTime)
		this.time = dateFormatterService.formattedTime(this.reservation.startDateTime)

	},
	mounted() {
	},
	methods: {
		async handleCancelReservation() {
			this.cancelReservationButtonText = "Cancelando <i class=\"fa fa-spinner fa-spin\"></i>";
			const result = await store.dispatch('user/deleteReservation', {reservation: this.reservation})
			console.log(result)
			if (result.result < 0) {
				console.log("No se ha cancelado la reserva");
				this.showToast('cancelReservationIncorrectly');
			}
			if (result.result === 0) {
				console.log("Reserva cancelada con éxito");
				this.showToast('cancelReservationCorrectly');
			}
			this.cancelReservationButtonText = "Cancelar reserva";
		},
		showToast(toastId) {
			const toastElement = document.getElementById(toastId);
			if (toastElement) {
				const toastInstance = bootstrap.Toast.getOrCreateInstance(toastElement);
				toastInstance.show();
			} else {
				console.error("Toast element not found:", toastId);
			}
		}
	}
};
</script>
