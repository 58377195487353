<script setup>
import Picker from '../amsul/pickadate/picker'
import $ from 'jquery';
import datePicker from '../amsul/pickadate/picker.date';
import {onMounted, computed,} from 'vue';
import { useStore } from 'vuex';
import {formatDateTime, settingsDatePicker} from "@/utils";

// eslint-disable-next-line no-undef
const props = defineProps({
  startDate: {
    type: String,
    default: null
  }
});

let reservationDate = null;
let datePickerObject = null;

const store = useStore();
const selectedDate = computed(() => store.state.reservationForm.selectedDate ? formatDateTime(store.state.reservationForm.selectedDate) : null);

const onSetDatePicker = ()=>{
	store.commit('reservationForm/setSelectedDate', {selectedDate: datePickerObject.get('select', 'yyyy-mm-dd') + ""});
}


onMounted(() => {
  if (props.startDate) {
    store.commit('reservationForm/setSelectedDate', {selectedDate:props.startDate.slice(0, 10)});
  }

  const picker = Picker($)

  datePicker(picker, $);

  reservationDate = $("#input-date").pickadate({...settingsDatePicker, onSet: onSetDatePicker});

  datePickerObject = reservationDate.pickadate('picker');
});

</script>

<style>
@import '../amsul/pickadate/pickadate-wewelcom/default.css';
@import '../amsul/pickadate/pickadate-wewelcom/default.date.css';
</style>

<template>
  <div class="col-12 col-md-6">
    <div class="input-group">
      <span class="input-group-text"><i class="fas fa-calendar"></i></span>
      <input id="input-date" type="text" class="form-control" placeholder="Día:"  v-model="selectedDate">
    </div>
  </div>
</template>

