
export class FaviconService {

	constructor() {
	}

	changeFavicon(weWelcomUrl) {

		let src = "/assets/img/favicon/favicon-32x32-prod.png";
		if (weWelcomUrl === "https://test.wewelcom.io"){
			src = "/assets/img/favicon/favicon-32x32-test.png";
		} else if (weWelcomUrl === "https://dev.wewelcom.io"){
			src = "/assets/img/favicon/favicon-32x32-dev.png";
		}

		const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
		link.type = 'image/png';
		link.rel = 'icon';
		link.href = src;
		document.getElementsByTagName('head')[0].appendChild(link);
	}


	changeFaviconByRoute(routePath) {
		let faviconSrc = "/world/img/default/favicons/wewelcom/favicon.ico";
		let appleTouchIconSrc = "/world/img/default/favicons/wewelcom/apple-touch-icon.png";
		switch (routePath) {
			case '/invitacion':
				break;
			case '/':
				faviconSrc = "/world/img/default/favicons/wepass/favicon.ico";
				appleTouchIconSrc = "/world/img/default/favicons/wepass/apple-touch-icon.png";
				break;
			default:
				break;
		}

		this.updateIcons(faviconSrc, appleTouchIconSrc);
		console.log("Favicon cambiado según la ruta: " + routePath);
	}

	updateIcons(faviconHref, appleTouchIconHref) {
		this.updateLinkAttributes('icon', 'image/png', faviconHref, '32x32');
		this.updateLinkAttributes('icon', 'image/png', faviconHref, '16x16');
		this.updateLinkAttributes('apple-touch-icon', null, appleTouchIconHref, '180x180');
		//this.updateLinkAttributes('manifest', null, '/site.webmanifest');
	}

	updateLinkAttributes(rel, type, href, sizes = null) {
		const link = document.querySelector(`link[rel="${rel}"][sizes="${sizes}"]`) || document.createElement('link');
		if (type) link.type = type;
		link.rel = rel;
		link.href = href;
		if (sizes) link.sizes = sizes;
		document.getElementsByTagName('head')[0].appendChild(link);
	}
}

export const faviconService = new FaviconService();
