import {reservationRepository} from "@/service/repository/reservationRepository";
import {userRepository} from "@/service/repository/userRepository";
// import { router } from '../main';

const state = {
	isValidReservation: true,
	reservationInfo: null,
	hostUserName: null,
	isInvitationMenu: false,
	hostCode: null
};

const getters = {
	isValidReservation(state) {
		return state.isValidReservation
	},
	isWalking(state) {
		return state.reservationInfo && state.reservationInfo.id.includes('WALKIN');
	},
	reservationInfo(state) {
		return state.reservationInfo
	},
	venueParams(state) {
		return state.reservationInfo.venueParams ? state.reservationInfo.venueParams : null;
	},
	invitedUsers(state, getters) {
		if (!state.reservationInfo.reservationUsers) {
			return [];
		}

		return getters.isWalking // Se usa getters.isWalking en lugar de this.isWalking
			? state.reservationInfo.reservationUsers
			: state.reservationInfo.reservationUsers.filter(user => user.type === 2);
	},
	creatorUser(state) {
		return state.reservationInfo.reservationUsers ? state.reservationInfo.reservationUsers.find(user => user.type === 1) : null;
	},
	hostUserName(state) {
		return state.hostUserName;
	},
	isInvitationMenu(state) {
		return state.isInvitationMenu;
	},
};

const mutations = {};

const actions = {
	async loadInvitationInfo({state}, {user}) {
		const values = window.location.search;
		const urlParams = new URLSearchParams(values);
		const reservationWid = urlParams.get('wid');
		state.hostCode = urlParams.get('hostCode');
		const paramMenu = urlParams.get('menu')
		state.isInvitationMenu = paramMenu === '1';

		if (!reservationWid) return;

		const responseReservationInfo = await reservationRepository.find(reservationWid);

		// const currentDate = new Date();
		// const reservationDate = new Date(responseReservationInfo.reservation.startDateTime);
		// We disable the date check because it might be for a guest that arrives to a just created reservation (or one hour ago) or walk in
		if (/*currentDate > reservationDate || */responseReservationInfo.reservation.status < 1) {
			return state.isValidReservation = false;
		}

		state.reservationInfo = responseReservationInfo.reservation;

		if (state.hostCode) {
			const hostUser = await userRepository.find(state.hostCode);
			state.hostUserName = hostUser.user.name;
		}
		console.log(user)
		return user
		// if (state.isInvitationMenu && user && state.isValidReservation) {
		// 	const result = await reservationRepository.addInvitedUser(reservationWid, user.id)
		// 	if (result.result === 0) {
		// 		// window.location.href = `https://${state.reservationInfo.venueParams.subdomain}.wewelcom.io/menu?r=${state.reservationInfo.id}&uid=${user.hostCode}`;
		// 		console.log(result)
		// 		console.log(user)
		// 		return user
		// 	}
		// }
		//
		// if (!state.isInvitationMenu && user && state.isValidReservation) {
		// 	const result = await reservationRepository.addInvitedUser(reservationWid, user.id)
		// 	if (result.result === 0) {
		// 		// return await router.push(`/?uid=${user.hostCode}`)
		// 		console.log(result)
		// 		console.log(user)
		// 		return user
		// 	}
		// }
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};
