<div class="container p-0">
	<div :data-bs-theme='reservation.venueParams.resource_id' class="card">
		<div class="card-header">
			<h3>{{ reservation.venueParams.business_name }}</h3>
		</div>
		<div class="row g-0">
			<div class="col-lg-4 align-items-center h-100">
				<div class="info-inblock info-inblock-primary">
					<div>{{ date }}</div>
					<div class="vr-point"></div>
					<div>{{ time }} h</div>
					<div class="vr-point"></div>
					<div>{{ reservation.pax }} Pers</div>
				</div>
				<div class="info-inblock info-inblock-black">
					<div class="info-reservation-name" data-bs-toggle="tooltip" data-bs-placement="top" :data-bs-title="invitedUsersString" type="button">
						{{ reservation.name }} <span class="mx-1">+</span>
						<div id="guests" v-if="invitedUsers.length">
							<!-- Mostrar nombres de invitados limitados por reservation.pax -->
							<span>{{ displayInvitedUsers }}</span>
						</div>
						<div v-else>
							No hay asistentes
						</div>
						<!-- Mostrar el resto de invitados si hay más -->
<!--						<div v-if="invitedUsers.length > reservation.pax && invitedUsers.length" class="text-danger">-->
<!--							<span class="ms-1">+</span>-->
<!--							<span>{{ invitedUsers.length - reservation.pax }}</span>-->
<!--						</div>-->

					</div>
				</div>


				<div class="hidden display-lg">
					<ul class="list-group list-group-flush">
						<!--						<li class="list-group-item">-->
						<!--							<button :id="'id-' + reservation.id" :class="{ 'disabled': reservation.menuAccessEnabled === 0 }" :disabled="reservation.finishedDateTime" class="btn btn-primary w-100" @click="redirectToMenu">{{ reservation.finishedDateTime ? 'La reserva ha finalizado' : 'Comenzar a pedir' }}-->
						<!--								<i v-if="reservation.menuAccessEnabled === 0" class="fas fa-lock"></i>-->
						<!--								<i v-else class="fas fa-lock-open"></i>-->
						<!--							</button>-->
						<!--						</li>-->
						<li class="list-group-item">
							<div v-if="isCreator && !reservation.finishedDateTime && reservation.menuAccessEnabled !== 1" class="col-12">
								<a :data-bs-target="'#modalUpdateReserve-' + reservation.id" class="btn btn-outline-primary w-100" data-bs-toggle="modal">
									Modificar reserva
								</a>
							</div>
						</li>
						<li class="list-group-item">
							<a :data-bs-target="'#modalGoogleMaps-' + reservation.id" data-bs-toggle="modal" class="btn btn-outline-primary w-100 h-100 align-content-center">
								<i class="fas fa-map-marker-alt"></i> Cómo llegar
							</a>
						</li>
						<li class="list-group-item">
							<a v-if="isCreator && !reservation.finishedDateTime && reservation.menuAccessEnabled !== 1" id="btn-cancel-reservation" :data-bs-target="'#modalCancelReserve-' + reservation.id" class="btn btn-outline-primary w-100" data-bs-toggle="modal">
								Cancelar reserva
							</a>
							<a v-if="!isCreator && !reservation.finishedDateTime" :data-bs-target="'#modalCancelAssistance-' + reservation.id" class="btn btn-outline-primary w-100" data-bs-toggle="modal">
								{{ cancelAssistanceButtonText }}
							</a>
							<div v-if="!isCreator && reservation.finishedDateTime || isCreator && reservation.finishedDateTime">
								<b>Reserva finalizada </b>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col-lg-8">
				<div class="card-body h-100 align-content-center">
					<div class="card-content-btn">
						<a class="btn btn-whatsapp w-100" :href="shareInvitationLink" target="_blank"><i class="fab fa-whatsapp"></i> Comparte tu reserva</a>
					</div>
					<p class="card-text">Para que cada miembro del grupo tenga su QR.</p>
					<button :id="'id-' + reservation.id" :class="{ 'disabled': reservation.menuAccessEnabled === 0 }" :disabled="reservation.finishedDateTime" class="btn btn-primary w-100" @click="redirectToMenu">
						{{ reservation.finishedDateTime ? 'La reserva ha finalizado' : updateOrderButtonText }}
						<i v-if="reservation.menuAccessEnabled === 0" class="fas fa-lock"></i>
						<i v-else class="fas fa-lock-open"></i>
					</button>
					<p class="card-text">Escaneando el
						<b>QR</b>, todos podrán acceder a la carta digital y pedir desde su móvil.</p>
				</div>
			</div>
		</div>
		<div class="hidden-md">
			<ul class="list-group list-group-flush" style="border-radius: 0">
				<!--				<li class="list-group-item">-->
				<!--					<button :id="'id-' + reservation.id" :class="{ 'disabled': reservation.menuAccessEnabled === 0 }" :disabled="reservation.finishedDateTime" class="btn btn-primary w-100" @click="redirectToMenu">{{ reservation.finishedDateTime ? 'La reserva ha finalizado' : 'Comenzar a pedir' }}-->
				<!--						<i v-if="reservation.menuAccessEnabled === 0" class="fas fa-lock"></i>-->
				<!--						<i v-else class="fas fa-lock-open"></i>-->
				<!--					</button>-->
				<!--					<p class="card-text">Escaneado el <b>QR</b> al llegar, todos tendrán acceso a la carta digital y podrán realizar los pedidos de inmediato.</p>-->
				<!--				</li>-->
				<li class="list-group-item">
					<div class="row row-column-gap">
						<div v-if="isCreator && !reservation.finishedDateTime && reservation.menuAccessEnabled !== 1" class="col-12">
							<a :data-bs-target="'#modalUpdateReserve-' + reservation.id" class="btn btn-outline-primary w-100" data-bs-toggle="modal">
								Modificar reserva
							</a>
						</div>
						<div class="col-6" v-if="shouldRenderCol6()">
							<a v-if="isCreator && !reservation.finishedDateTime && reservation.menuAccessEnabled !== 1" id="btn-cancel-reservation" :data-bs-target="'#modalCancelReserve-' + reservation.id" class="btn btn-outline-primary w-100" data-bs-toggle="modal">
								Cancelar reserva
							</a>
							<a v-if="!isCreator && !reservation.finishedDateTime" :data-bs-target="'#modalCancelAssistance-' + reservation.id" class="btn btn-outline-primary w-100" data-bs-toggle="modal">
								{{ cancelAssistanceButtonText }}
							</a>
							<div v-if="!isCreator && reservation.finishedDateTime || isCreator && reservation.finishedDateTime">
								<b>Reserva finalizada </b>
							</div>
						</div>
						<div class="col">
							<a :data-bs-target="'#modalGoogleMaps-' + reservation.id" data-bs-toggle="modal" class="btn btn-outline-primary w-100 h-100 align-content-center">
								<i class="fas fa-map-marker-alt"></i> Cómo llegar
							</a>
						</div>
					</div>
				</li>

			</ul>
		</div>
	</div>
</div>

<!--Todo: Sacamos el modal fuera de la tarjeta hasta que mostremos todas las reservas-->
<cancel-reservation-modal :reservation="reservation"></cancel-reservation-modal>

<!--Alerts scanner QR-->
<div class="toast-container position-fixed end-0 p-3" style="top: 60px;">
	<div id="accessToMenuCorrectly" class="toast align-items-center toast-success" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="d-flex">
			<div class="toast-body">
				Ya puedes comenzar a pedir desde tu móvil.
			</div>
			<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
	</div>
	<div id="accessToMenuIncorrectly" class="toast align-items-center toast-danger" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="d-flex">
			<div class="toast-body">
				No se han podido activar tu reserva.
			</div>
			<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
	</div>
</div>

<!-- Modal Cancelar asistencia -->
<cancel-assistance-modal :reservation="reservation" :key="reservation.id"></cancel-assistance-modal>

<!-- Modal modificar reserva -->
<update-reservation-modal :reservation="reservation" ></update-reservation-modal>

<!-- Modal google maps-->
<div :id="'modalGoogleMaps-' + reservation.id" aria-hidden="true" aria-labelledby="modalGoogleMap" class="modal fade" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-body">
				<div class="map-container">
					<iframe :src='venueParams.location_gmaps_url' width="600" height="600" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
			<div class="modal-footer">

				<button class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">Cerrar</button>
			</div>
		</div>
	</div>
</div>
