<template>
	<div v-if="user && !isLoading" class="container-section">
		<section class="border-responsive">
			<div class="section-body section-qr">
<!--				@click="venueParams.business_name.toLowerCase().includes('wewelcom_disabled') ? qrClicked() : null"-->
				<QRCodeVue class="content-qr qr-float" :value="user.uid" :width="150" :margin="2" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'" />
			</div>
			<div class="section-footer">
				<div v-if="isValidateUser">
					<span class="fs-4">
						Presenta tu <b>QR</b> al llegar</span> <br>
					y comparte tu <b>reserva</b>.

					<!--					Presenta <b>tu QR</b> al llegar, <b>comparte tu reserva</b> para que todos se <b>beneficien</b>.-->
				</div>
				<div v-else>
					<span class="fs-4">Presenta tu <b>QR</b> al llegar</span>

				</div>
			</div>

		</section>
	</div>
	<div v-else class="container-section placeholder-glow">
		<section class="border-responsive">
			<div class="section-body section-qr">
				<span class="content-qr qr-float placeholder" style="width: 150px; height: 150px"></span>
			</div>
			<div class="section-footer">
				<div>
					<span class="placeholder col-8"></span>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
import QRCodeVue from "vue-qrcode";
import {mapGetters} from "vuex";
export default {
	name: 'QRContainer',
	components: {
		QRCodeVue
	},
	data() {
		return {
			isLoading: true,
			isValidateUser:false,
		};
	},
	beforeMount() {
		this.isValidateUser = this.user.type === 2

	},
	mounted() {
		this.isLoading = false
	},
	computed: {
		...mapGetters('user',['user','userReservations']),
	},
	methods: {
		async qrClicked() {
			let agent = "WELCOM PASS TEST";

			let data = {
				"code": this.user.hostCode,
				"agent": agent,
				"type": 1,
				"text": "Escaneo de pass demo",
			}

			try {
				let checkCodeResponse = await this.userRepository.registerLog(data);

				console.log("La respuesta del servidor para el check del código fue ");
				console.log(checkCodeResponse);
			} catch (e) {
				console.log("Error: " + e);
			}
		},
	}
}
</script>
