<!-- Modal Reserva invalida para cancelar -->
<div id="modalInvalidCancelReserve" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h1 id="exampleModalLabel" class="modal-title">
					<i class="fas fa-exclamation-circle"></i> Lo sentimos, la reserva que deseas cancelar no esta disponible.</h1>
				<button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
			</div>
			<div class="modal-body">
				<div class="info text-center container-section">
					<section >
						<div >
							<a class="btn btn-primary" :href="reserveUrl">Hacer otra reserva</a>
						</div>
					</section>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">Salir</button>
			</div>
		</div>
	</div>
</div>
