<div v-if="userReservations.length" v-for="reservation in userReservations">
	<pass-reservation-card  :reservation="reservation"></pass-reservation-card>
</div>
<div v-else>
	<section class="container">
		<div class="section-header">
			No tienes reservas o invitaciones pendientes.
		</div>
	</section>

	<section class="container" v-if="!isValidateUser">
		<div class="section-header">
			Obtén tu QR único y disfruta de múltiples beneficios:
		</div>
		<div class="section-body">
			<ul>
				<li>Haz reservas fácilmente desde tu móvil.</li>
				<li>Accede a la carta digital y realiza pedidos al instante.</li>
				<li>Explora y disfruta de tus restaurantes favoritos.</li>
				<li>Crea momentos inolvidables con tus seres queridos.</li>
			</ul>
<!--			<a class="btn btn-primary" :href="registerUrl">Crea tu cuenta ahora</a>-->
		</div>
	</section>
</div>

<!-- TODO: cuando solo mostramos una sola reserva-->
<!--<cancel-reservation-modal :reservation="reservation" :key="reservation.id" v-for="reservation in userReservations"></cancel-reservation-modal>-->
