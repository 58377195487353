<!-- Modal Cancelar -->
<div :id="'modalCancelReserve-' + reservation.id" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" tabindex="-1">
	<div class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h1 id="exampleModalLabel" class="modal-title">
					<i class="fas fa-exclamation-circle"></i> ¿Deseas cancelar esta reserva?</h1>
				<button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
			</div>
			<div class="modal-body">
				<p class="text-center">Para cancelar tu reserva, haz clic en el botón <b>"CANCELAR"</b> de esta ventana.
				</p>

				<div class="reserve-card-container">
					<div class="reserve-card-type">
						<div :class="isCreator ? 'reserve' : 'invitation'" class="reserve-type">
							Reserva <span v-if="!isCreator">de</span>:
						</div>
						<div v-if="!isCreator" class="reserve-name">
							{{ reservation.name }}
						</div>
					</div>
					<div class="reserve-card-content-modal">
						<div class="row">
							<div class="col-12">
								<i class="fas fa-calendar"></i> {{ date }}
							</div>

						</div>
						<div class="row">
							<div class="col-4">
								<i class="fas fa-clock"></i> {{ time }}h
							</div>
							<div class="col-3">
								<i class="fas fa-users"></i> {{ reservation.pax }}
							</div>
<!--							<div v-if="reservation.roomName" class="col-5">-->
<!--								<svg class="table-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 20 140 80" width="200" fill="">-->
<!--									<rect x="10" y="30" width="120" height="20" rx="5" ry="5" fill="inherit"/>-->
<!--									<rect x="20" y="40" width="20" height="50" rx="5" ry="5" fill="inherit"/>-->
<!--									<rect x="100" y="40" width="20" height="50" rx="5" ry="5" fill="inherit"/>-->
<!--								</svg>-->
<!--								{{ reservation.roomName }}-->
<!--							</div>-->
						</div>


					</div>
				</div>

				<div class="info text-center">
					<span>Asegúrate de que sea la reserva que deseas cancelar.</span>
				</div>
			</div>
			<div class="modal-footer">
				<button class="btn btn-outline-primary" data-bs-dismiss="modal" type="button">Salir</button>
				<button class="btn btn-primary" @click="handleCancelReservation">
					<span v-html="cancelReservationButtonText"></span></button>
			</div>
		</div>
	</div>
</div>
<!--Alerts scanner QR-->
<div class="toast-container position-fixed end-0 p-3" style="top: 0;">
	<div id="cancelReservationCorrectly" class="toast align-items-center toast-success" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="d-flex">
			<div class="toast-body">
				Se ha cancelado correctamente tu reserva.
			</div>
			<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
	</div>
	<div id="cancelReservationIncorrectly" class="toast align-items-center bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
		<div class="d-flex">
			<div class="toast-body">
				No se han podido cancelar tu reserva.
			</div>
			<button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
		</div>
	</div>
</div>
