<template src="./invalidReservationModal.html">
</template>

<script>
import {mapGetters} from "vuex";

export default {
	beforeMount() {
		console.log('Montando el modal de invalid reservation')
	},
	mounted() {
	},
	computed:{
		...mapGetters('user', ['reserveUrl']),
	},
	methods: {}
};
</script>
