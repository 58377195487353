import { createStore } from 'vuex';
import userModule from './userModule';
import reservationFormModule from "@/store/reservationFormModule";
import configModule from "@/store/configModule";
import wePassModule from "@/store/wePassModule";
import invitationModule from "@/store/invitationModule";

export default createStore({
    modules: {
        user: userModule,
        reservationForm: reservationFormModule,
		config: configModule,
		wePass: wePassModule,
		invitation: invitationModule,
    }
});
