import {sendRequestJson} from '@/utils'

export class AllergenRepository {

	constructor() {

		let host = window.location.hostname;
		if (host === "localhost"){
			this.urlBase = 'https://dev.wepass.ai';
		} else {
			this.urlBase = window.location.origin;
		}
	}

	async allergensList() {
		const url = this.urlBase + "/world/api/allergens/list"
		try {
			return await sendRequestJson({data:null, url, method:"GET"});
		} catch (e) {
			console.log("Ups, algo ha ido mal obteniendo los alergenos.");
		}
	}

}

export const allergenRepository = new AllergenRepository();
